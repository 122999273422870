<template>
  <div>
  <headBack>
    <template v-slot:title>
      <div class='title'>
        春节服务公告
      </div>
    </template>
  </headBack>
  <div class='header_cont'></div>
  <div class="wrapper">
    <div class="tit">
      春节期间服务公告
    </div>
    平台在2月9日-2月14日期间加收服务调度费<br>
    2月9日（三十）-2月11日（初二） 加收150元<br>
    2月12日（初三）-2月14日（初五） 加收100元<br>

    <div class="text">
      另：<br>
      血检及基因检测类产品可先行下单，客服后续联系补收春节服务调度费；<br>
      由于各城市样本检测机构春节放假时间不一，遇无法进行服务，平台将进行退款。<br>
    </div>

  </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="scss">
.wrapper{
  font-size: 28px;
  box-sizing: border-box;
  padding:30px;
  color:#666;

}
.text{
  margin-top: 30px;
}
.tit{
  font-weight: bold;
  margin-bottom: 10px;
  color:#666;
}
</style>
